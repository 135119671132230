import { LoginIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

export function SignedOutMenu() {
  let nav_link_class =
    "ml-4 text-gray-700 hover:text-indigo-500 cursor-pointer";

  return (
    <>
      <Link to="/boards">
        <LoginIcon className="ml-4 mr-1 h-5 pt-0.5 text-gray-500 hover:text-indigo-500 cursor-pointer" />
      </Link>
      <Link to="/boards">
        <p className={"ml-1" + nav_link_class}>Login</p>
      </Link>
    </>
  );
}
