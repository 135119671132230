export function Home() {
  return (
    <>
      <div
        className={"flex pt-8 mb-8 justify-center"}
        style={{
          backgroundImage: "url('/images/top_back.jpg')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "700px",
        }}
      >
        <div className="my-12 mx-12 max-w-5xl">
          <div
            className={"object-center grid gap-8"}
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 0.6fr)",
            }}
          >
            <div className={""}>
              <h1 className="text-sans text-white text-6xl font-bold">
                Welcome!
              </h1>
              <p className="my-6 font-sans text-white">
                Thank you for trying the beta version of Planningmate. You can
                use it for free. The only string attached is that we want to
                hear what you think of Planningmate, how you have used it and
                what you think we should do better.
              </p>
              <p className={"my-6 font-sans text-white"}>
                We will reach out to you by email in June 2022. By signing up
                you approve of us contacting you on the email-adress you submit.
              </p>
              <p className={"my-6 font-sans text-white"}>
                Click 'Login' in the menu, create an account and start creating
                your boards!
              </p>
            </div>
            <div>
              <img
                alt={"Calendar and notepad"}
                className={"rounded-xl"}
                src={"/images/calendar_notepad.jpg"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
