/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      name
      description
      headers
      subtasks
      authors
      invitedUsers
      invitedUsersInfo
      assignedUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        headers
        subtasks
        authors
        invitedUsers
        invitedUsersInfo
        assignedUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
