import { useState } from "react";

const emptyForm = {
  displayName: "",
  loginName: "",
};
export const InviteDialog = ({ id, inviteFn }) => {
  const [invitee, setInvitee] = useState(emptyForm);

  const primaryButton =
    "px-2 py-1 text-xs font-xs text-white whitespace-no-wrap" +
    " bg-blue-600 border border-blue-700 rounded-md shadow-sm" +
    " hover:bg-blue-700" +
    " focus:outline-none focus:ring-2" +
    " focus:ring-offset-2 focus:ring-blue-500";
  const secondaryButton =
    "px-3 py-1 text-xs font-xs bg-blue-100" +
    " hover:bg-blue-200 hover:text-blue-700" +
    " text-blue-600 rounded-md shadow-sm text-xs";
  const inpuField =
    "text-sm py-1 px-1.5 text-gray-900 " +
    "border rounded w-full " +
    "focus:outline-none focus:shadow-outline";

  return (
    <dialog
      id={id}
      className={
        "open:left-1/2 open:top-1/2" +
        " -translate-x-1/2 -translate-y-1/2" +
        " m-0 w-80 rounded-md open:p-4 bg-white" +
        " open:flex open:flex-col open:gap-3 open:backdrop:bg-black/20"
      }
    >
      <div className={"mb-2 border-b border-gray-400"}>
        <div className={"text-sm text"}>Add a contributor</div>
      </div>
      <div className="">
        <input
          className={inpuField}
          type="text"
          placeholder={"Display Name"}
          value={invitee.displayName}
          onChange={(event) => {
            setInvitee({ ...invitee, displayName: event.target.value });
          }}
        />
      </div>
      <div>
        <input
          className={inpuField}
          type="text"
          placeholder={"Username"}
          value={invitee.loginName}
          onChange={(event) => {
            setInvitee({ ...invitee, loginName: event.target.value });
          }}
        />
      </div>
      <div className={"flex flex-row-reverse gap-2"}>
        <button
          disabled={invitee.displayName === "" || invitee.loginName === ""}
          type="button"
          className={primaryButton}
          onClick={() => {
            inviteFn(invitee);
            setInvitee(emptyForm);
            document.getElementById(id).close();
          }}
        >
          Add
        </button>
        <button
          type="button"
          className={secondaryButton}
          onClick={() => {
            setInvitee(emptyForm);
            document.getElementById(id).close();
          }}
        >
          Close
        </button>
      </div>
    </dialog>
  );
};
