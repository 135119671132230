import { ClipboardListIcon } from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import { Amplify, Auth, Hub, Logger } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { SignedOutMenu } from "./SignedOutMenu";
import { SignedInMenu } from "./SignedInMenu";

export function Menu() {
  Amplify.Logger.LOG_LEVEL = "INFO";

  const [user, setUser] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    const logger = new Logger("menu-logger");
    const setUserAndGoToBoards = () => {
      getAndSetUser();
      navigate("/");
    };
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("event = '" + event + "'");
      switch (event) {
        case "signIn":
          logger.info("user signed in");
          setUserAndGoToBoards();
          break;
        case "cognitoHostedUI":
          logger.info("cognitoHostedUI setting user");
          setUserAndGoToBoards();
          break;
        case "signOut":
          setUser(null);
          return navigate("/");
        case "signIn_failure":
          logger.error("user sign in failed", data);
          break;
        case "cognitoHostedUI_failure":
          logger.error("Sign in failure", data);
          break;
        case "signUp":
          logger.info("user signed up");
          break;
        case "tokenRefresh":
          logger.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          logger.error("token refresh failed");
          break;
        case "configured":
          logger.info("the Auth module is configured");
          break;
        default:
          logger.info("Default reached");
          break;
      }
    });

    getAndSetUser();
  }, []);

  function getAndSetUser() {
    // console.log("Menu: getting the user from Auth.currentAuthenticatedUser()");
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        // console.log("  got the user", userData);
        return setUser(userData);
      })
      .catch(() => {
        // console.log("Menu: Not signed in........................");
        setUser(null);
      });
  }
  return (
    <nav className="bg-white shadow">
      <div className="flex px-6 py-1">
        <div className="flex items-center">
          <div className="">
            <Link to="/">
              <ClipboardListIcon className="mr-2 my-2 h-6" />
            </Link>
          </div>
          <div>
            <Link
              className="text-xl font-bold text-gray-800 hover:text-gray-700"
              to="/"
            >
              Planning Mate
            </Link>
          </div>
        </div>
        <div className="grow" />
        <div className="flex justify-end items-center">
          <Link
            to="/contact"
            className={
              "ml-4 text-gray-700 hover:text-indigo-500 cursor-pointer"
            }
          >
            Contact
          </Link>
          {user ? <SignedInMenu /> : <SignedOutMenu />}
        </div>
      </div>
    </nav>
  );
}
