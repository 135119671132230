import { useContext, useEffect, useRef, useState } from "react";
import ContentEditable from "./ContentEditable";
import { removeColumnIfLast, updateColumn } from "../../slice";
import DOMPurify from "dompurify";
import { BoardContext } from "../Board";
import { useMutation, useQueryClient } from "react-query";
import { saveBoard } from "../../api";

const HeaderItem = ({ id, text }) => {
  const textarea = useRef(null);
  const queryClient = useQueryClient();
  const mutation = useMutation(saveBoard, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
  const board = useContext(BoardContext).board;
  const [stateText, setStateText] = useState(text);

  useEffect(() => {
    const focus = () => () => {
      const range = document.createRange();
      if (!textarea.current.childNodes[0]) {
        return;
      }
      range.setStart(textarea.current.childNodes[0], 0);
      range.setEnd(
        textarea.current.childNodes[textarea.current.childNodes.length - 1],
        textarea.current.childNodes[textarea.current.childNodes.length - 1]
          .length
      );
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    };
    const focusText = focus();
    textarea.current && textarea.current.addEventListener("focus", focusText);
    const el = textarea.current;
    return () => el && el.removeEventListener("focus", focusText);
  }, [stateText]);

  return (
    <div
      className={
        " sticky top-0 items-center backdrop-blur" +
        " flex justify-center p-2 mt-2 text-center" +
        " space-y-2 text-sm font-semibold text-gray-500"
      }
    >
      <ContentEditable
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            document.activeElement.blur();
            window.getSelection().removeAllRanges();
          }
        }}
        onBlur={() => {
          let name = DOMPurify.sanitize(stateText, { ALLOWED_TAGS: [] });
          if (name === "") {
            // removeColumnIfLast(board, { index: id });
            mutation.mutate(removeColumnIfLast(board, { index: id }));
          } else {
            // const b = updateColumn(board, { index: id, name });
            // saveBoard(b)
            mutation.mutate(updateColumn(board, { index: id, name }));
          }
        }}
        innerRef={textarea}
        html={stateText}
        onChange={(e) => {
          setStateText(e.target.value);
        }}
        className="py-1 px-2"
      />
    </div>
  );
};

export default HeaderItem;
