import ManagementLevel from "./ManagementLevel";
import { addColumn, addTask, moveTo } from "../../slice";
import HeaderItem from "./HeaderItem";
import { useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useMutation, useQueryClient } from "react-query";
import { BoardContext } from "../Board";
import { calcHeight, calcWidth } from "../../util";
import { saveBoard } from "../../api";
import { Plus } from "./Icons";

const ManagementBoard = () => {
  const queryClient = useQueryClient();
  const board = useContext(BoardContext).board;
  // console.log("MB: ", board);
  const managementVision = board === undefined ? {} : board;
  const mutate = useMutation(saveBoard, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const [newColDragTarget, setNewColDragTarget] = useState(false);
  const [newVisionDragTarget, setNewVisionDragTarget] = useState(false);

  useEffect(() => {
    // const ws = apiWs(board.id)
    // ws.onmessage = (m) => queryClient.invalidateQueries(['board'])
  }, []);

  if (managementVision === null || managementVision.headers === undefined) {
    return <div>No board</div>;
  }

  const width = Math.max(
    calcWidth(managementVision) - 1,
    managementVision.headers.length
  );
  const height = calcHeight(managementVision);

  const addStyleBase =
    "flex m-2 p-2 space-y-2 transition-all opacity-0" +
    " cursor-pointer justify-center rounded-md items-center" +
    " text-center sm:text-left text-sm font-semibold" +
    " text-gray-300 border-2 border-dashed border-gray-300" +
    " hover:border-gray-400" +
    " hover:bg-gray-200" +
    " hover:text-gray-500" +
    " group-hover:opacity-100";
  const addColumnStyle = `${addStyleBase} ${
    newColDragTarget ? "bg-gray-300" : ""
  }`;
  const addVisionStyle = `${addStyleBase} ${
    newVisionDragTarget ? "bg-gray-300" : ""
  }`;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={"py-2"}>
        <div
          className="py-4"
          style={{ minWidth: width * 100 + (width - 1) * 20 + 70 + "px" }}
        >
          <div
            className={
              "relative group p-2 border-2 min border-dashed border-gray-200 " +
              "rounded-lg grid bg-gray-50 gap-4"
            }
            style={{
              gridTemplateRows: `40px repeat(${height}, minmax(0, 1fr)) 60px`,
              gridTemplateColumns: `repeat(${width}, minmax(0, 1fr)) 80px`,
              justifyContent: "start",
            }}
          >
            {managementVision.headers.map((header, i) => (
              <HeaderItem text={header} id={i} key={header} />
            ))}
            <ManagementLevel
              name={"Vision"}
              id={[]}
              children={managementVision.subtasks}
              start={2}
              end={height}
              level={1}
            />
            <div
              style={{
                gridColumn: 1,
                gridRowStart: height + 2,
                gridRowEnd: height + 3,
              }}
              onClick={() => {
                mutate.mutate(addTask(board));
              }}
              className={addVisionStyle}
              onDrop={(e) => {
                let b = moveTo(board, {
                  from: JSON.parse(e.dataTransfer.getData("text")),
                  to: undefined,
                });
                if (b !== "") {
                  mutate.mutate(b);
                } else {
                  console.log("Did not really move...");
                }
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                setNewVisionDragTarget(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setNewVisionDragTarget(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                setNewVisionDragTarget(true);
              }}
            >
              <Plus className="w-5 h-5" />
            </div>
            <div
              className={addColumnStyle}
              style={{
                gridColumn: width + 1,
                gridRowStart: 2,
                gridRowEnd: height + 2,
              }}
              onClick={() => {
                mutate.mutate(addColumn(board));
              }}
              onDragEnter={(e) => {
                e.preventDefault();
                setNewColDragTarget(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setNewColDragTarget(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                setNewColDragTarget(true);
              }}
            >
              <Plus className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ManagementBoard;
