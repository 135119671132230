import ManagementBoard from "./ManagementBoard/ManagementBoard";
import Name from "./ManagementBoard/Name";
import { Link, useParams } from "react-router-dom";
import { fetchBoard } from "../api";
import { useQuery } from "react-query";
import React from "react";
import { Loading } from "./Loading";

export const BoardContext = React.createContext();

export const Board = () => {
  const params = useParams();
  const boardId = params.id;
  const { isLoading, isError, data, error } = useQuery(
    ["board", boardId],
    fetchBoard(boardId)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    const backToBoards = (
      <span>
        Go back to your{" "}
        <Link to="/boards" className="underline">
          boards
        </Link>
      </span>
    );
    let errorMessage = "Oh no! An unexpected error occured...";
    if (error.errors) {
      if (error.errors[0].errorType === "Unauthorized") {
        errorMessage =
          "You are not authorized to view board with id: " + boardId;
      }
    }
    return (
      <div className="container mx-auto my-6">
        <div>{errorMessage}</div>
        <div>{backToBoards}</div>
      </div>
    );
  }

  return (
    <BoardContext.Provider value={{ board: data }}>
      <div className="px-6">
        <header className="border-b-2 border-gray-100 py-6">
          <Name name={data.name} />
        </header>
        <main>
          <ManagementBoard />
        </main>
      </div>
    </BoardContext.Provider>
  );
};
