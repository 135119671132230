import { useContext, useEffect, useRef, useState } from "react";
import ContentEditable from "./ContentEditable";
import DOMPurify from "dompurify";
import { updateName } from "../../slice";
import { BoardContext } from "../Board";
import { useMutation, useQueryClient } from "react-query";
import { saveBoard } from "../../api";

const Name = ({ name }) => {
  const board = useContext(BoardContext).board;
  // const saveBoard = useContext(BoardContext).saveBoard
  const [stateText, setStateText] = useState(name);
  const queryClient = useQueryClient();
  const mutation = useMutation(saveBoard, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
  const textarea = useRef(null);

  useEffect(() => {
    const focus = () => {
      const range = document.createRange();
      if (!textarea.current.childNodes[0]) {
        return;
      }
      range.setStart(textarea.current.childNodes[0], 0);
      range.setEnd(
        textarea.current.childNodes[textarea.current.childNodes.length - 1],
        textarea.current.childNodes[textarea.current.childNodes.length - 1]
          .length
      );
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    };
    textarea.current && textarea.current.addEventListener("focus", focus);
    const el = textarea.current;
    return () => el && el.removeEventListener("focus", focus);
  }, [stateText]);

  useEffect(() => {
    setStateText(name);
  }, [name]);

  return (
    <ContentEditable
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          document.activeElement.blur();
          window.getSelection().removeAllRanges();
        }
      }}
      onChange={(e) => setStateText(e.target.value)}
      onBlur={() => {
        let name = DOMPurify.sanitize(stateText, { ALLOWED_TAGS: [] });
        mutation.mutate(updateName(board, { name }));
      }}
      innerRef={textarea}
      html={stateText}
      className="text-xl font-semibold"
    />
  );
};

export default Name;
