const sum = (a, b) => a + b;

const range = (i) => [...Array(i).keys()];

const calcWidth = (obj = {}) => {
  return obj.subtasks !== undefined && obj.subtasks.length
    ? 1 + Math.max(...obj.subtasks.map(calcWidth))
    : 1;
};
const calcHeight = (obj = {}) => {
  return obj.subtasks !== undefined && obj.subtasks.length !== 0
    ? obj.subtasks.map(calcHeight).reduce(sum, 0)
    : 1;
};

const calcWidth2 = (root) => {
  const width = (subtasks, depth) => {
    console.log(depth);
    if (subtasks === undefined || subtasks.length === 0) {
      return depth - 1;
    }
    console.log("längd: " + subtasks.length);
    const [head, ...tail] = subtasks;
    return Math.max(width(head.subtasks, depth + 1), width(tail, depth + 1));
  };
  return Math.max(width(root.subtasks, 0), 0) + 1;
};

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
export { sum, range, calcHeight, calcWidth, calcWidth2, isInViewport };
