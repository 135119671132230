import { useContext, useEffect, useRef, useState } from "react";
import ContentEditable from "./ContentEditable";
import DOMPurify from "dompurify";
import { moveTo, updateDueDate, updateText } from "../../slice";
import { useMutation, useQueryClient } from "react-query";
import { BoardContext } from "../Board";
import { saveBoard } from "../../api";
import { CircleMenu } from "./CircleMenu";
import { defaultTextColor } from "../../constants";
import "react-datepicker/dist/react-datepicker.css";
import { DateSelector } from "./DateSelector";

const ManagementBox = ({
  id,
  column,
  span,
  text,
  color,
  isLeaf,
  theDueDate,
}) => {
  const queryClient = useQueryClient();
  const saveBoardMutation = useMutation(saveBoard, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
  const [stateText, setStateText] = useState(text);
  const [taskStyle, setTaskStyle] = useState();
  const board = useContext(BoardContext).board;
  const [showMenu, setShowMenu] = useState(false);
  const textarea = useRef(null);
  const element = useRef(null);
  const [dragTarget, setDragTarget] = useState(false);
  // const dispatch = useDispatch()
  const [dueDate, setDueDate] = useState(theDueDate);

  const onSaveDueDate = (date) => {
    setDueDate(date);
    const dateISO = date ? date.toISOString() : null;
    saveBoardMutation.mutate(updateDueDate(board, { path: id, date: dateISO }));
  };

  useEffect(() => {
    const focus = (stateText) => () => {
      const range = document.createRange();
      if (!textarea.current.childNodes[0]) {
        return;
      }
      range.setStart(textarea.current.childNodes[0], 0);
      range.setEnd(
        textarea.current.childNodes[textarea.current.childNodes.length - 1],
        textarea.current.childNodes[textarea.current.childNodes.length - 1]
          .length
      );
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    };
    const focusText = focus(stateText);
    textarea.current && textarea.current.addEventListener("focus", focusText);
    const el = textarea.current;
    return () => el && el.removeEventListener("focus", focusText);
  }, [stateText]);

  useEffect(() => {
    let style = {
      gridColumnStart: column,
      gridColumnEnd: column + 1,
      gridRowStart: span.start,
      gridRowEnd: span.end,
      // alignSelf: "start",
    };
    if (color.fill) {
      style = {
        ...style,
        backgroundColor: color.taskColor,
        color: color.textColor,
        borderWidth: "2px",
        borderColor: color.taskColor,
      };
    } else {
      style = {
        ...style,
        borderWidth: "2px",
        borderColor: color.taskColor,
        color: defaultTextColor,
      };
    }
    setTaskStyle(style);
  }, [color, column, span]);

  return (
    <div
      className={`group py-3 pb-1 rounded-md shadow col-span-1 
      flex items-center flex-col justify-start 
       ${dragTarget ? "bg-gray-200" : "bg-white"}`}
      style={taskStyle}
      ref={element}
      onMouseLeave={() => {
        setShowMenu(false);
      }}
      onDrop={(e) => {
        const b = moveTo(board, {
          from: JSON.parse(e.dataTransfer.getData("text")),
          to: id,
        });
        if (b !== "") {
          saveBoardMutation.mutate(b);
        }
        setDragTarget(false);
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        setDragTarget(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setDragTarget(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setDragTarget(true);
      }}
      draggable={true}
      onDragStart={(e) => {
        e.dataTransfer.setData("text/plain", JSON.stringify(id));
      }}
    >
      <div className="bg-gray-300 cursor-pointer rounded-full w-5 h-1" />
      <div className={"flex justify-center self-stretch flex-col grow"}>
        <ContentEditable
          onClick={(e) => {
            element.current.draggable = false;
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              document.activeElement.blur();
              window.getSelection().removeAllRanges();
            }
          }}
          onChange={(e) => setStateText(e.target.value)}
          onBlur={() => {
            let name = DOMPurify.sanitize(stateText, { ALLOWED_TAGS: [] });
            element.current.draggable = true;
            saveBoardMutation.mutate(updateText(board, { path: id, name }));
          }}
          innerRef={textarea}
          html={stateText}
          className={
            "whitespace-normal max-w-full p-2 text-center space-y-2 text-sm font-medium self-center"
          }
          style={{
            color: taskStyle
              ? taskStyle.color
                ? taskStyle.color
                : color.textColor
              : color.textColor,
          }}
        />
      </div>
      <div className={"flex flex-row justify-between w-full h-5"}>
        <DateSelector date={dueDate} setDate={onSaveDueDate} hide={showMenu} />
        <CircleMenu
          id={id}
          color={color}
          board={board}
          showMenu={showMenu}
          saveBoardMutation={saveBoardMutation}
          setShowMenu={setShowMenu}
          isLeaf={isLeaf}
        />
      </div>
    </div>
  );
};

export default ManagementBox;
