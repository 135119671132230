import { calcWidth } from "./util";
import { v4 as uuidv4 } from "uuid";
import { defaultColor } from "./constants";

const newSubTask = () => {
  return {
    id: uuidv4(),
    name: "New Task",
    color: defaultColor,
    subtasks: [],
    dueDate: null,
  };
};

const actions = {
  addTask: (board, action) => {
    // console.log("addTask - action", action);
    if (action?.path) {
      // console.log("  action?.path", action?.path);
      let obj = board;
      for (const index of action.path) {
        // console.log("index = '" + index + "'");
        if (obj.subtasks !== undefined) {
          // console.log("obj.subtasks", obj.subtasks);
          obj = obj.subtasks[index];
        } else {
          // console.log("sätter obj till tomt");
          obj = {};
        }
      }
      if (obj.subtasks) {
        // console.log('pushar till subtasks');
        obj.subtasks.push(newSubTask());
      } else {
        // console.log('sätter subtasks');
        obj.subtasks = [newSubTask()];
      }
    } else {
      // console.log('path var inte satt pushar till subtasks direkt');
      board.subtasks.push(newSubTask());
    }
    return actions.addColumnsIfNeeded(board);
  },
  addColumnsIfNeeded: (board) => {
    // console.log("addColumnsIfNeeded", board);
    let diff = Math.max(calcWidth(board) - board.headers.length - 1, 0);
    for (let i = 0; i < diff; i++) {
      board.headers.push(`Unnamed Column ${board.headers.length}`);
    }
    return board;
  },
  removeTask: (board, action) => {
    // console.log("removeTask", board, action);
    let obj = board;
    for (const index of action.path.slice(0, -1)) {
      obj = obj.subtasks[index];
    }
    const lastIndex = action.path[action.path.length - 1];
    obj.subtasks = obj.subtasks.filter((task, i) => {
      return i !== lastIndex;
    });
    return removeColumnIfLast(board, { index: action.path.length - 1 });
  },
  addColumn: (board) => {
    // console.log("----------- addColumn", board);
    board.headers.push("Unnamed Column " + (board.headers.length + 1));
    return board;
  },
  updateColumn: (board, action) => {
    // console.log("updateColumn", board, action);
    board.headers[action.index] = action.name;
    return board;
  },
  removeColumnIfLast: (board, action) => {
    // console.log("removeColumnIfLast", board, action);
    if (action.index === board.headers.length - 1) {
      if (calcWidth(board) - 2 >= action.index) {
        return board;
      }
      board.headers.splice(action.index, 1);
    }
    return board;
  },
  updateText: (board, action) => {
    // console.log("updateText", board, action);
    let obj = board;
    for (const index of action.path) {
      obj = obj.subtasks[index];
    }
    obj.name = action.name;
    // console.log("board after update text: ", board);
    return board;
  },
  moveTo: (board, action) => {
    let toStr = JSON.stringify(action.to);
    let fromStr = JSON.stringify(action.from);
    if (toStr === fromStr) {
      // console.log("not really moving, returning...");
      return "";
    }
    if (
      action.to &&
      fromStr === JSON.stringify(action.to.slice(0, action.from.length))
    ) {
      // console.log("dropping parent on child");
      return "";
    }

    // let fromParent = action.from.slice(0, action.from.length - 1);
    // const fromParentStr = JSON.stringify(fromParent);
    // if (action.to && toStr === fromParentStr) {
    //   console.log('dropped on current parent, not moving');
    //   return "";
    // }
    const from_task = action.from.reduce(
      (acc, cur) => acc.subtasks[cur],
      board
    );
    if (action.to === undefined) {
      board.subtasks.push(from_task);
    } else {
      const to_task = action.to.reduce((acc, cur) => acc.subtasks[cur], board);
      to_task.subtasks.push(from_task);
    }
    action.from
      .slice(0, -1)
      .reduce((acc, cur) => acc.subtasks[cur], board)
      .subtasks.splice(action.from.slice(-1)[0], 1);
    // board
    return actions.addColumnsIfNeeded(board);
  },
  updateName: (board, action) => {
    board.name = action.name;
    return board;
  },
  updateDescription: (board, action) => {
    board.description = action.description;
    return board;
  },
  updateColor: (board, action) => {
    // console.log("updateColor", board);
    let obj = board;
    for (const index of action.path) {
      obj = obj.subtasks[index];
    }
    obj.color = action.color;
    return board;
  },
  updateDueDate: (board, action) => {
    //console.log("updateDueDate", action);
    let obj = board;
    for (const index of action.path) {
      obj = obj.subtasks[index];
    }
    obj.dueDate = action.date;
    return board;
  },
};

export const {
  addTask,
  removeTask,
  addColumn,
  updateColumn,
  removeColumnIfLast,
  updateText,
  moveTo,
  updateName,
  updateColor,
  updateDueDate,
} = actions;

// export default managementBoardSlice.reducer
