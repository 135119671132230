import "@aws-amplify/ui-react/styles.css";
import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addBoard, boards, saveBoard } from "../api";
import BoardRow from "./BoardRow";
import { Loading } from "./Loading";

const emptyBoard = { name: "", description: "" };

export function Boards() {
  Amplify.Logger.LOG_LEVEL = "INFO";

  const queryClient = useQueryClient();

  const [user, setUser] = useState();
  const [board, setBoard] = useState(emptyBoard);

  const { isLoading, isError, data, error } = useQuery(
    ["boards", user],
    boards(user)
  );

  useEffect(() => {
    getAndSetUser();
  }, []);

  const resetBoardForm = () => setBoard(emptyBoard);

  function clearFormAndInvalidateQueries() {
    resetBoardForm();
    queryClient.invalidateQueries(["boards"]);
  }

  const addMutation = useMutation(addBoard, {
    onSuccess: () => {
      clearFormAndInvalidateQueries();
    },
  });

  const saveMutation = useMutation(saveBoard, {
    onSuccess: () => {
      clearFormAndInvalidateQueries();
    },
  });

  const saveOrUpdate = () => {
    if (board.id) {
      saveMutation.mutate(board);
    } else {
      addMutation.mutate({ newBoard: board, user: user });
    }
    setBoard(emptyBoard);
  };

  const addInviteFn = (board) => {
    return (invitee) => {
      const { displayName, loginName } = invitee;
      if (!board.invitedUsers) {
        board.invitedUsers = [];
      }
      board.invitedUsers.push(loginName);
      if (!board.invitedUsersInfo) {
        board.invitedUsersInfo = {};
      }
      board.invitedUsersInfo[loginName] = displayName;
      saveMutation.mutate(board);
    };
  };
  const deleteInviteFn = (board) => {
    return (deletedLoginName) => {
      board.invitedUsers = board.invitedUsers.filter(
        (loginName) => loginName !== deletedLoginName
      );
      delete board.invitedUsersInfo[deletedLoginName];
      saveMutation.mutate(board);
    };
  };

  function getAndSetUser() {
    // console.log(
    //   "Boards: getting the öser from Auth.currentAuthenticatedUser()"
    // );
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        // console.log("  got the user", userData);
        return setUser(userData);
      })
      .catch(() => {
        // console.log("Boards: Not signed in........................");
        setUser(null);
      });
  }

  // function setInput(key, value) {
  //   const v = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
  //   let theform = { ...boardForm, [key]: v };
  //   setBoardForm(theform);
  // }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    if (!user) {
      // console.log("Error and no user, showing login");
      return <Authenticator />;
    }
    return <span>Boards Error: {error.message}</span>;
  }

  let inpuField =
    "text-sm py-1 px-1.5 mb-2 text-gray-900 " +
    "border rounded " +
    "focus:outline-none focus:shadow-outline w-2/5";
  if (!user) {
    return <Authenticator />;
  }

  const primaryButton =
    "px-2 py-1 text-xs font-xs text-white whitespace-no-wrap bg-blue-600 border border-blue-700 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";
  const secondaryButton =
    "px-3 py-1 text-xs font-xs bg-blue-100 hover:bg-blue-200 hover:text-blue-700 text-blue-600 rounded-md shadow-sm text-xs";

  const th_class =
    " bg-gray-200 text-gray-500 align-middle border border-solid border-gray-100 py-2 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left";

  return (
    <div className="container mx-6 my-6">
      <header className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
        <span className={"text-xl font-semibold"}>Planning Boards</span>
      </header>
      <main>
        <div className="grid grid-cols-[2fr_4fr_4fr_1fr]  border">
          <div className={"px-3" + th_class}>Board name</div>
          <div className={"px-6" + th_class}>Description</div>
          <div className={"px-6" + th_class}>Contributors</div>
          <div className={"px-6" + th_class}>Actions</div>
          {data
            .sort((b1, b2) => b1.name.localeCompare(b2.name))
            .map((b) => (
              <BoardRow
                key={b.id}
                board={b}
                setBoardFn={setBoard}
                clearFormFn={resetBoardForm}
                inviteFn={addInviteFn(b)}
                deleteInviteFn={deleteInviteFn(b)}
              />
            ))}
        </div>
      </main>
      <div className="container mx-auto mt-10 pl-4">
        <div className={"text-gray-700 text-sm"}>
          <label htmlFor={"board_name"}>Board name</label>
        </div>
        <div className={"text-gray-700"}>
          <input
            id={"board_name"}
            type="text"
            className={inpuField}
            value={board.name}
            onChange={(event) => {
              setBoard({ ...board, name: event.target.value });
            }}
          />
        </div>
        <div className={"text-gray-700 text-sm"}>
          <label htmlFor={"board_description"}>Description</label>
        </div>
        <div className={"text-gray-700"}>
          <input
            id={"board_description"}
            type="text"
            className={inpuField}
            value={board.description}
            onChange={(event) => {
              setBoard({ ...board, description: event.target.value });
            }}
          />
        </div>
        <div className={"flex gap-2"}>
          <button
            type="button"
            className={secondaryButton}
            onClick={resetBoardForm}
          >
            Reset
          </button>
          <button
            disabled={board === emptyBoard}
            type="button"
            className={primaryButton}
            onClick={saveOrUpdate}
          >
            {(board && board.id ? "Update" : "Create") + " Board"}
          </button>
        </div>
        {/*<pre className={"text-xs"}> {JSON.stringify(user, null, 2)} </pre>*/}
      </div>
      {user && (
        <div className={"mt-8 border-t border-gray-300"}>
          <div className="text-xs text-gray-500">
            Logged in as: {user.username}
          </div>
        </div>
      )}
    </div>
  );
}
