import { defaultTextColor } from "../../constants";
import { isInViewport } from "../../util";
import { useEffect, useState } from "react";

export const ColorPicker = ({
  visible,
  setVisible,
  setColorSelected,
  toggleFill,
  currentColor,
}) => {
  const colors = [
    { textColor: defaultTextColor, taskColor: "#FFF", fill: false },
    { textColor: defaultTextColor, taskColor: "#DDD", fill: false },
    { textColor: defaultTextColor, taskColor: "#BBB", fill: true },
    { textColor: "white", taskColor: "#000", fill: false },
    { textColor: "white", taskColor: "#3182ce", fill: false },
    { textColor: "white", taskColor: "#7FDBFF", fill: false },
    { textColor: "white", taskColor: "#39CCCC", fill: false },
    { textColor: "white", taskColor: "#3D9970", fill: false },
    { textColor: "white", taskColor: "#2ECC40", fill: false },
    { textColor: "white", taskColor: "#01FF70", fill: false },
    { textColor: defaultTextColor, taskColor: "#FFDC00", fill: false },
    { textColor: "white", taskColor: "#FF851B", fill: false },
    { textColor: "white", taskColor: "#FF4136", fill: false },
    { textColor: "white", taskColor: "#85144B", fill: false },
    { textColor: "white", taskColor: "#F012BE", fill: true },
    { textColor: "white", taskColor: "#B10DC9", fill: false },
  ];
  const [checked, setChecked] = useState(currentColor.fill);
  const [checkBoxStyle, setCheckBoxStyle] = useState({
    backgroundColor: "white",
  });

  const defColor = "#3182ce";
  const gray300 = "#e2e8f0";

  useEffect(() => {
    const the_picker = document.getElementById("picker");
    if (the_picker && !isInViewport(the_picker)) {
      the_picker.scrollIntoView(false);
    }
  }, [visible]);

  useEffect(() => {
    let style = {
      backgroundColor: "white",
    };
    if (checked) {
      style = {
        backgroundColor: defColor,
        right: 0,
      };
    }
    setCheckBoxStyle((prevStyle) => style);
  }, [checked]);

  function select(color) {
    setColorSelected({ ...color, fill: checked });
    setVisible(false);
  }

  return (
    <>
      {visible ? (
        <div
          id={"picker"}
          className={
            "origin-top-right absolute right-0 mt-1 w-44" +
            " rounded-md shadow-lg py-2 pr-2 pl-3.5 divide-y" +
            " origin-top-right absolute -right-16 mt-1 w-44" +
            " rounded-md shadow-lg py-2 pr-2 pl-3.5 divide-y bg-white"
          }
        >
          <div className={"pb-2 text-center"}>
            <div className={"rounded-md bg-white shadow-xs"}>
              <div className="relative inline-block w-8 mr-2 align-middle select-none transition duration-700 ease-in">
                <input
                  type="checkbox"
                  name="toggle"
                  id="toggle"
                  checked={checked}
                  className="absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none cursor-pointer"
                  style={checkBoxStyle}
                  onChange={() => {
                    setChecked((prevState) => !prevState);
                    // let colorWithUpdatedFill = {
                    //   ...currentColor,
                    //   fill: checked,
                    // };
                    toggleFill();
                    // setColorSelected(colorWithUpdatedFill);
                  }}
                />{" "}
                <label
                  htmlFor="toggle"
                  className="toggle-label block overflow-hidden h-4 rounded-full cursor-pointer"
                  style={{ backgroundColor: checked ? defColor : gray300 }}
                />
              </div>
              <label htmlFor="toggle" className="text-sm text-gray-700">
                {" "}
                Fill background{" "}
              </label>{" "}
            </div>
          </div>
          <div className={"pt-2 flex flex-wrap gap-1.5"}>
            {colors.map((color) => {
              return (
                <div
                  key={color.taskColor}
                  className={
                    "w-8 h-8 inline-flex rounded-full cursor-pointer border-4 " +
                    "border-white focus:outline-none focus:shadow-outline " +
                    "hover:ring-1"
                  }
                  style={{
                    backgroundColor: color.taskColor,
                  }}
                  onClick={() => select(color)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
