export function Contact() {
  return (
    <>
      <div className="container mx-auto my-6">
        <h1 className="text-xl">Contact Planningmate</h1>
        <div>
          <p className="mt-2">Storgatan 12</p>
          <p>123 45 Stad</p>
          <a className="underline" href="mailto:contact@planningmate.com">
            info@planningmate.com
          </a>
        </div>
      </div>
    </>
  );
}
