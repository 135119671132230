import "@aws-amplify/ui-react/styles.css";
import Alert from "./Alert";
import React, { useState } from "react";
import { Edit, Trash, UserAdd } from "./ManagementBoard/Icons";
import { InviteDialog } from "./InviteDialog";
import { Link } from "react-router-dom";
import { removeBoard } from "../api";
import { useMutation, useQueryClient } from "react-query";

export default function BoardRow({
  board,
  setBoardFn,
  clearFormFn,
  inviteFn,
  deleteInviteFn,
}) {
  const queryClient = useQueryClient();

  const [alertIsShowing, setAlertIsShowing] = useState(false);

  const id = "invite-dialog-" + board.id;
  const removeMutation = useMutation(removeBoard, {
    onSuccess: () => {
      queryClient
        .invalidateQueries(["boards"])
        .then((r) => console.log("Promise returned: " + r));
    },
  });

  return (
    <>
      <div className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-left text-gray-900">
        <Link to={"/boards/" + board.id}>{board.name}</Link>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm p-2 text-gray-900">
        <Link to={"/boards/" + board.id}>{board.description}</Link>
      </div>
      <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 p-2">
        <div className={"flex flex-wrap gap-2"}>
          {board.invitedUsers &&
            board.invitedUsers.map((invited) => {
              const invitedDisplayName =
                board.invitedUsersInfo && board.invitedUsersInfo[invited];
              return (
                <div
                  key={invited + "-" + board.id}
                  style={{
                    fontSize: "0.6rem",
                    paddingTop: ".2rem",
                    paddingBottom: ".2rem",
                  }}
                  className={
                    "border border-indigo-500 shadow-sm shadow-gray-400 text-gray-900 rounded-sm pr-2 flex items-center"
                  }
                >
                  <div
                    className={"ml-1 flex-none cursor-pointer"}
                    onClick={() => deleteInviteFn(invited)}
                  >
                    x
                  </div>
                  <div
                    className={
                      "mx-1.5 flex-none overflow-ellipsis whitespace-nowrap overflow-hidden"
                    }
                    title={invitedDisplayName + ", <" + invited + ">"}
                  >
                    {invitedDisplayName}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="border-t-0 px-6 align-center border-l-0 border-r-0 whitespace-nowrap p-2">
        <div className="flex relative">
          <div className="flex gap-x-2 items-center">
            <div className={""}>
              <button
                title={"Add contributor"}
                onClick={() => document.getElementById(id).showModal()}
              >
                <UserAdd className={"w-4 h-4"} />
              </button>
              <InviteDialog id={id} inviteFn={inviteFn} />
            </div>
            <div>
              <button
                title={"Edit name or description"}
                onClick={() => setBoardFn(board)}
              >
                <Edit className={"w-4 h-4"} />
              </button>
            </div>
            <div>
              <button
                title={"Delete board"}
                onClick={() => setAlertIsShowing(true)}
              >
                <Trash className={"w-4 h-4"} />
              </button>
            </div>
          </div>
        </div>
        <Alert
          warningText={
            "Are you sure you want to delete board '" +
            board.name +
            "'?" +
            " This action cannot be undone."
          }
          open={alertIsShowing}
          setOpen={setAlertIsShowing}
          okCallback={() => {
            clearFormFn();
            removeMutation.mutate(board);
          }}
        />
      </div>
    </>
  );
}
