import ManagementBox from "./ManagementBox";
import { calcHeight, sum } from "../../util";
import { defaultColor } from "../../constants";
import React from "react";

const ManagementLevel = ({ name, children, id, level, start, end }) => {
  const heights = children.map((c) => {
    return calcHeight(c);
  });
  const soFar = (heights, i) => heights.slice(0, i).reduce(sum, 0);
  return (
    <>
      {children.map((child, i) => {
        const childStart = start + soFar(heights, i);
        const childEnd = childStart + heights[i];
        const d = child.dueDate ? new Date(child.dueDate) : null;
        return (
          <React.Fragment key={child.id + "level" + child.name}>
            <ManagementBox
              key={child.id + child.name}
              column={level}
              span={{ start: childStart, end: childEnd }}
              id={[...id, i]}
              text={child.name}
              color={child.color ? child.color : defaultColor}
              isLeaf={child.subtasks === null || child.subtasks.length === 0}
              theDueDate={d}
            />
            {child.subtasks ? (
              <>
                <ManagementLevel
                  key={child.id + "level" + child.name}
                  start={childStart}
                  end={childEnd}
                  children={child.subtasks}
                  id={[...id, i]}
                  level={level + 1}
                />
              </>
            ) : null}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ManagementLevel;
