import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Menu } from "./components/Menu.js";
import { Home } from "./components/Home.js";
import { Boards } from "./components/Boards.js";
import { Board } from "./components/Board.js";
import { Admin } from "./components/Admin.js";
import { Contact } from "./components/Contact.js";
import { ErrorBoundary } from "./components/ErrorBoundary.js";

import { Amplify } from "aws-amplify";
import config from "./aws-exports";

Amplify.configure(config);

function App() {
  return (
    <>
      <ErrorBoundary>
        <Router>
          <Menu />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/boards" element={<Boards />} />
            <Route path="/boards/:id" element={<Board />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;
