import { API, graphqlOperation } from "aws-amplify";
import { getBoard, listBoards } from "./graphql/queries";
import { createBoard, deleteBoard, updateBoard } from "./graphql/mutations";
import { v4 as uuidv4 } from "uuid";

const inProduction = process.env.NODE_ENV === "production";
const host = inProduction ? "planningmate.com" : "localhost:3000";
const webSocketOrigin = (inProduction ? "wss://" : "ws://") + host;

function parseFromDb(theBoard) {
  const parsedSubtasks = JSON.parse(theBoard.subtasks);
  const parsedInvitedInfo = JSON.parse(theBoard.invitedUsersInfo);
  const newBoard = {
    ...theBoard,
    subtasks: parsedSubtasks,
    invitedUsersInfo: parsedInvitedInfo,
  };
  // console.log('### After parseFromDb');
  // console.log(newBoard);
  return newBoard;
}

function prepareForDb(theBoard) {
  // console.log("prepare", theBoard);
  const { createdAt, updatedAt, owner, ...restOfBoard } = theBoard;
  const subtasksAsString = JSON.stringify(theBoard.subtasks);
  const invitedInfoAsString = JSON.stringify(theBoard.invitedUsersInfo);
  const updatedBoard = {
    ...restOfBoard,
    subtasks: subtasksAsString,
    invitedUsersInfo: invitedInfoAsString,
  };
  // console.log("Updating board with:");
  // console.log(updatedBoard);
  return updatedBoard;
}

export const fetchBoard = (boardId) => async () => {
  // console.log("api: Board: in getBoard");
  const boardData = await API.graphql(
    graphqlOperation(getBoard, { id: boardId })
  );
  // console.log("Board: got boardData: ", boardData);
  return parseFromDb(boardData.data.getBoard);
};

export const boards = (user) => async () => {
  // console.log("api: ---- Fetching boards for user", user);
  if (user === undefined || user === null) {
    // console.log("api: user is not set yet, returning...");
  }
  const boardData = await API.graphql(
    // graphqlOperation(listBoards, { filter: { authors: { contains: email } } })
    graphqlOperation(listBoards)
  );
  // const boardData = await API.graphql(graphqlOperation(listBoards));
  const boards = boardData.data.listBoards.items;
  return boards.map((board) => parseFromDb(board));
};

export const addBoard = async (data) => {
  const { newBoard, user } = data;
  // console.log("api: newBoard", newBoard);
  // console.log("user", user);
  // console.log("Boards: in addBoard");
  // console.log("Boards: JSON.stringify(user) = '" + JSON.stringify(user) + "'");
  if (!newBoard.name) {
    return;
  }
  const board = {
    ...newBoard,
    subtasks: JSON.stringify([
      { id: uuidv4(), name: "First Task", subtasks: [] },
    ]),
    headers: ["First Step"],
    authors: [user.attributes.email],
  };
  return API.graphql(graphqlOperation(createBoard, { input: board }));
};

export const saveBoard = async (theBoard) => {
  // console.log("api: saveBoard() called with board:", theBoard);
  if (theBoard === undefined || theBoard === null) {
    throw new Error("No board supplied while saving the board");
  }
  const updatedBoard = prepareForDb(theBoard);
  return API.graphql(graphqlOperation(updateBoard, { input: updatedBoard }));
};

export async function removeBoard(theBoard) {
  return API.graphql(
    graphqlOperation(deleteBoard, { input: { id: theBoard.id } })
  );
}

export const ws = (board_id) => new WebSocket(webSocketOrigin + "/" + board_id);
