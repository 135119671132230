/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      id
      name
      description
      headers
      subtasks
      authors
      invitedUsers
      invitedUsersInfo
      assignedUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      name
      description
      headers
      subtasks
      authors
      invitedUsers
      invitedUsersInfo
      assignedUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      name
      description
      headers
      subtasks
      authors
      invitedUsers
      invitedUsersInfo
      assignedUser
      createdAt
      updatedAt
      owner
    }
  }
`;
