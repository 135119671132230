import { forwardRef } from "react";
import { Calendar } from "./Icons";
import DatePicker from "react-datepicker";

export function DateSelector({ date, setDate, hide }) {
  const DatePickerWrapper = forwardRef(({ value, onClick }, ref) => (
    <div
      className={
        "@container flex items-center h-5 text-xxs stransition-opacity opacity-0 group-hover:opacity-100 text-gray-500 cursor-pointer"
      }
    >
      <div title={value}>
        <Calendar className={"w-3.5 h-3.5 ml-2"} onClick={onClick} />
      </div>
      <div
        className={
          "mx-1 mt-0.5 whitespace-nowrap overflow-clip overflow-ellipsis"
        }
        onClick={onClick}
        title={value}
      >
        {value}
      </div>
      {value && (
        <div
          className="mr-2 text-xxs text-red-500"
          onClick={() => setDate(null)}
          title={"Remove due date"}
        >
          x
        </div>
      )}
    </div>
  ));

  const theDatePicker = (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      showYearDropdown={true}
      selected={date}
      onChange={setDate}
      customInput={<DatePickerWrapper />}
    />
  );

  if (!hide) {
    return theDatePicker;
  } else {
    return <div></div>;
  }
}
