import { Cancel, Dots, Plus, Trash } from "./Icons";
import { addTask, removeTask, updateColor } from "../../slice";
import Alert from "../Alert";
import { useEffect, useState } from "react";
import { ColorPicker } from "./ColorPicker";

export const CircleMenu = ({
  id,
  color,
  board,
  showMenu,
  saveBoardMutation,
  setShowMenu,
  isLeaf,
}) => {
  const [alertIsShowing, setAlertIsShowing] = useState(false);
  const [colorSelected, setColorSelected] = useState(color);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  useEffect(() => {
    if (!showMenu) {
      setColorPickerVisible(false);
    }
  }, [showMenu]);

  function deleteAndSaveBoard() {
    return saveBoardMutation.mutate(removeTask(board, { path: id }));
  }

  const pickColor = (color) => {
    setColorSelected(color);
    setColorPickerVisible(false);
    setShowMenu(false);
    saveBoardMutation.mutate(updateColor(board, { path: id, color }));
  };

  const toggleFill = () => {
    setColorSelected((prevColor) => {
      let newColor = { ...prevColor, fill: !prevColor.fill };
      saveBoardMutation.mutate(
        updateColor(board, { path: id, color: newColor })
      );
      return newColor;
    });
  };

  const ringBase = "w-5 h-5 cursor-pointer transition-colors";
  const ringClass = `${ringBase}  
    ${
      colorSelected.textColor === "white"
        ? " text-gray-400 hover:text-gray-200"
        : " text-gray-400 hover:text-gray-500"
    }`;
  const trashClass = `${ringBase}
    ${
      colorSelected.textColor === "white"
        ? " text-red-400 hover:text-red-200"
        : " text-red-700 hover:text-red-400"
    }`;
  const colorRingClass = `h-2 w-2 rounded-full p-2 align-top ring-1 ring-inset 
    ${
      colorSelected.textColor === "white"
        ? " ring-gray-400 hover:ring-gray-200"
        : " ring-gray-400 hover:ring-gray-500"
    }`;
  return (
    <>
      <div
        className="transition-opacity opacity-0 group-hover:opacity-100
              flex flex-row items-center justify-end pr-1 h-5"
      >
        {showMenu ? (
          <>
            <div>
              <Trash
                className={trashClass}
                onClick={() =>
                  isLeaf ? deleteAndSaveBoard() : setAlertIsShowing(true)
                }
              />{" "}
            </div>
            <div className={"relative mt-0.5"}>
              <div
                className={colorRingClass}
                style={{ backgroundColor: colorSelected.taskColor }}
                onClick={() => setColorPickerVisible(!colorPickerVisible)}
              />
              <ColorPicker
                visible={colorPickerVisible}
                setVisible={setColorPickerVisible}
                setColorSelected={pickColor}
                toggleFill={toggleFill}
                currentColor={color}
              />
            </div>
            <div>
              <Plus
                onClick={() => {
                  saveBoardMutation.mutate(addTask(board, { path: id }));
                }}
                className={ringClass}
              />{" "}
            </div>
            <div>
              <Cancel
                className={ringClass}
                onClick={() => setShowMenu(false)}
              />
            </div>
          </>
        ) : (
          <div
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
            <Dots className={ringClass} onClick={() => setShowMenu(true)} />
          </div>
        )}
      </div>
      <Alert
        warningText={
          "Are you sure you want to delete this task? All of the" +
          " tasks sub-tasks will also be removed. This action" +
          " cannot be undone."
        }
        open={alertIsShowing}
        setOpen={setAlertIsShowing}
        okCallback={deleteAndSaveBoard}
      />
    </>
  );
};
