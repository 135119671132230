import { Auth } from "aws-amplify";
import { LogoutIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

export function SignedInMenu() {
  let nav_link_class =
    "ml-4 text-gray-700 hover:text-indigo-500 cursor-pointer";

  function logout() {
    Auth.signOut();
  }

  return (
    <>
      <Link to="/boards" className={nav_link_class}>
        Boards
      </Link>
      <div onClick={logout}>
        <LogoutIcon className="ml-4 mr-1 h-5 pt-0.5 text-gray-500 hover:text-indigo-500 cursor-pointer" />
      </div>
      <p onClick={logout} className={"ml-1" + nav_link_class}>
        Logout
      </p>
    </>
  );
}
